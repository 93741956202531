import React, { useEffect, useRef, useState } from "react";
import { ApiService } from "../../Components/Services/apiservices";
import { validEmail } from "../../Components/Elements/Regex";
import constant from "../../Components/Services/constant";
import { validNumber } from "../../Components/Elements/Regex";
import Alert from "react-bootstrap/Alert";
import Header from '../../Components/Header'
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { Col, FormLabel, Row } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useParams } from 'react-router-dom';
import Footer from "../../Components/Footer";
import { Helmet } from "react-helmet";



function Exports() {
const { slug } = useParams();
const didMountRef = useRef(true);
const [pageData, setPageData] = useState({});
const [pageContent, setPageContent] = useState("");
const [settingData, setSettingData] = useState([]);
const [errorMessage, setErrorMessage] = useState("");
const [successMessage, setSuccessMessage] = useState("");
const [contactDetails, setContactDetails] = useState({
  contact_name: "",
  contact_email: "",
  contact_subject: "",
  contact_mobile:"",
  contact_message: "",
});
 useEffect(() => {
 if (didMountRef.current){
    const getPageData = {
        slug: "exports",
      };
      ApiService.postData("page-content", getPageData).then((res) => {
        if (res.status == "success") {
            setPageData(res.data);
            setPageContent(res.data.page_content);
        }
      });
 }

 didMountRef.current=false;
 })


 const onTodoChange = (e) =>{
 const { name, value } = e.target;
 setContactDetails ((prevState) => ({
   ...prevState,
   [name]: value

 }))
 }

 const resetContactForm = () => {
  setContactDetails({
    contact_name: "",
    contact_email: "",
    contact_subject: "",
    contact_mobile: "",
    contact_message: "",
  });
};

 const contactusProcess = () => {
  let counter = 0;
  const myElements = document.getElementsByClassName("required");
  for (let i = 0; i < myElements.length; i++) {
    if (myElements[i].value === "") {
      myElements[i].style.border = "1px solid red";
      counter++;
    } else {
      myElements[i].style.border = "";
    }
  }
  if (counter === 0) {
    setErrorMessage("");
    if (!validEmail.test(contactDetails.contact_email)) {
      setErrorMessage("Please enter valid Email Id");
      return false;
    }
    if (!validNumber.test(contactDetails.contact_mobile)) {
      setErrorMessage("Please enter valid Mobile Number");
      return false;
    }
    ApiService.postData("contact-us-process", contactDetails).then((res) => {
      if (res.status === "success") {
        setSuccessMessage(res.message);
        resetContactForm();
        setTimeout(() => {
          setSuccessMessage("");
        }, 2000);
      } else {
        setErrorMessage(res.message);
        setTimeout(() => {
          setErrorMessage("");
        }, 2000);
      }
    });
  }
};

  return (
    <> 
             <Helmet>
        <title>{pageData.page_meta_title}</title>
        <meta name="description" itemprop="description" content={pageData.page_meta_desc != null ? pageData.page_meta_desc :""} />
        {pageData.page_meta_keyword != null ?<meta name="keywords" content={pageData.page_meta_keyword} />:""}
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content={pageData.page_meta_title} />
        <meta name="twitter:url" content={window.location.href} />
             <meta property="og:image" content= {constant.FRONT_URL+'img/logo.png'}/>
        <meta property="og:url" content={window.location.href} />
        {pageData.page_meta_desc != null ?<meta property="og:description" content={pageData.page_meta_desc} />:""}
        <meta name="twitter:title" content={pageData.page_meta_title} />
        {pageData.page_meta_desc != null ?<meta name="twitter:description" content={pageData.page_meta_desc} />:""}
            <meta property="twitter:image" content= {constant.FRONT_URL+'img/logo.png'}/>
        
      </Helmet>
    <Header/>
    <div className="subheader">
        <Container>
          <Row>
            <Col lg={12}>
              <h1>{pageData.page_name}</h1>
              <Breadcrumb>
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>{pageData.page_name}</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
        </Container>
      </div>

      <div dangerouslySetInnerHTML={{ __html: pageContent}}></div>
      {/* <section className="sec-pad pt-0 mt-5">
<div className="container">
<div className="row">

  <div className="col-md-4" style={{ textAlign: "center" }}>
    <img
      alt=""
      className="img-responsive"
      src="https://chokhidhanifoods.com/csadmin/public/img/uploads/media/1692336747.png"
      style={{ padding: "5px", objectFit: "cover", position: "relative" }}
    />
  </div>

  <div className="col-md-4" style={{ textAlign: "center" }}>
    <img
      alt=""
      className="img-responsive"
      src="https://chokhidhanifoods.com/csadmin/public/img/uploads/media/1692336760.png"
      style={{ padding: "5px", objectFit: "cover", position: "relative" }}
    />
  </div>

  <div className="col-md-4" style={{ textAlign: "center" }}>
    <img
      alt=""
      className="img-responsive"
      src="https://chokhidhanifoods.com/csadmin/public/img/uploads/media/1692336771.png"
      style={{ padding: "5px", objectFit: "cover", position: "relative" }}
    />
  </div>


</div>
</div>
</section> */}

      <section className="sec-pad">
        <Container>
          <div className="contactexport">
            
          <Row className="align-items-center" >
       
          <Col lg={5}>
          <div className="section-title mb-5">
                <h2>For Exports Info Contact</h2>
                <p>
                Have any Query related to exports? Let us Know!
                </p>
              </div>

       <div className="contact-address-section mb-3" >
         <div className="section-title">
           <h3>Ms. Durva Bhatnagar </h3>
           <p>Export Sales Manager I Chokhi Dhani Group (Chokhi Dhani Foods)</p>
         </div>
         <div className="address-contact">
         <ul>
             <li>
               <a href="tel: +9163772 67579">
               <i className="d-icon-phone mr-5"></i>
                 <span>Mobile : +91 63772 67579</span>
               </a>
             </li>
             <li>
               <a href="tel: 0141-5166000 ">
               <i className="d-icon-phone mr-5"></i>
                 <span>Landline : 0141-5166000 </span>
               </a>
             </li>
             <li>
               <a href="mailto:exports@chokhidhanifoods.com">
                 <i className="fa fa-envelope mr-5"></i>
                 <span>Email : exports@chokhidhanifoods.com</span>
               </a>
             </li>
           </ul>
         </div>
       </div>

 
       
     </Col>
            <Col lg={7}>
          
              {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
              {successMessage && (
                <Alert variant="success">{successMessage}</Alert>
              )}
              <div className="row g-3">
                <div className="col-lg-6">
                  <div className="form-group-dark">
                    <label>Your Name</label>
                    <input
                      type="text"
                      name="contact_name"
                      className="form-control required"
                      value={contactDetails.contact_name}
                      onChange={(e) => onTodoChange(e)}
                      placeholder="Name"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group-dark">
                    <label>Email Address</label>
                    <input
                      type="text"
                      name="contact_email"
                      className="form-control required"
                      value={contactDetails.contact_email}
                      onChange={(e) => onTodoChange(e)}
                      placeholder="Email"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group-dark">
                    <label>Mobile Number</label>
                    <input
                      type="text"
                      name="contact_mobile"
                      className="form-control required"
                      value={contactDetails.contact_mobile}
                      onChange={(e) => onTodoChange(e)}
                      placeholder="Mobile Number"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group-dark">
                    <label>Subject</label>
                    <input
                      type="text"
                      name="contact_subject"
                      className="form-control required"
                      value={contactDetails.contact_subject}
                      onChange={(e) => onTodoChange(e)}
                      placeholder="Subject"
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group-dark">
                    <label>Message</label>
                    <textarea
                      name="contact_message"
                      className="form-control required"
                      value={contactDetails.contact_message}
                      onChange={(e) => onTodoChange(e)}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <Button
                    className="btn btn-primary btn-medium"
                    onClick={contactusProcess}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </Col>
           
          </Row>
          </div>
        </Container>
      </section>




<Footer/>

    </>
  )
}

export default Exports
