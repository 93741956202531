import React, { useEffect, useRef, useState, useContext } from "react";
import Loader from "react-js-loader";
import DataContext from '../Elements/eventContext';
import multiCurrency from "../Elements/multi_currrency";
import { addToCart, minusToCart, removeToCart } from "../Elements/add_to_cart";
import sessionCartData from "../Elements/cart_session_data";
import constant from "../Services/constant";
import CouponModal from "./coupon_modal";
import { ApiService } from "../Services/apiservices";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
function PaymentModal() {
    const Navigate = useNavigate();
    const sessionData = sessionCartData();
    const addressSessionData = sessionData[0];
    const cartSessionData = sessionData[1];
    const couponSummary = sessionData[2];
    const cartSummary = sessionData[3];
    const contextValues = useContext(DataContext);
    const [initialLoading, setInitialLoading] = useState(true);
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState("pay-online");
    const [shippingCharge, setShippingCharge] = useState(0);
    const didMountRef = useRef(true);
    useEffect(() => {
        if (didMountRef.current) {
            getSettingsData()
            if (contextValues.userToken != '') {
                getUserSelectedAddress()
            } else {
                setTimeout(() => {
                    setInitialLoading(false)
                }, 500);
            }

        }
        didMountRef.current = false;
    }, [contextValues]);

    const getSettingsData = () => {
        ApiService.fetchData("settings").then((res) => {
            if (res.status === "success") {
                contextValues.setSettingData(res.sitesettings);
                contextValues.setSettingImageBaseUrl(res.setting_image_path);
                if (res.sitesettings.admin_cod_status === 1) {
                    setPaymentMethod('COD')
                    calculateShippingAmount('1')
                } else {
                    setPaymentMethod('pay-online')
                    calculateShippingAmount('0')
                }
            }
        });
    };

    const calculateShippingAmount = (paymentType) => {
        const dataString = {
            itemtotal: Number(cartSummary.total_amount) - Number(contextValues.couponSession && contextValues.couponSession.discount_amount ? contextValues.couponSession.discount_amount : 0),
            ua_id: contextValues.userSelectedAddress.ua_id,
            cart_data: contextValues.cartSessionData,
            payment_type: paymentType,
            payment_type_admin: contextValues.settingData.admin_cod_status
        };

        ApiService.postData("calculate-shipping-amount", dataString).then((res) => {
            if (res.status === "success") {
                setShippingCharge(res.shipping_amount);
                setSpinnerLoading(false);
            }
        });
    };

    const deleteToCart = async (productValue) => {
        setSpinnerLoading(true)
        const updateStatus = await removeToCart(productValue, productValue.product_variation, contextValues);
        if (updateStatus) {
            setTimeout(() => {
                let cartSessionCount = localStorage.getItem("CART_SESSION");
                cartSessionCount = cartSessionCount ? JSON.parse(cartSessionCount) : [];
                contextValues.setCartSessionData(cartSessionCount)
                contextValues.setCartCount(cartSessionCount.length)
                if (cartSessionCount.length == 0) {
                    window.location.reload()
                }
                setSpinnerLoading(false)
            }, 500);
        } else {
            setTimeout(() => {
                setSpinnerLoading(false)
            }, 500);
        }
    };

    const plusToCart = async (productValue, index) => {
        contextValues.setSpinnerCubLoader(index)
        const productData = {
            product_id: Number(productValue.product_id),
            product_name: productValue.product_name,
            product_slug: productValue.product_slug,
            product_image: productValue.product_image
                ? productValue.product_image
                : constant.DEFAULT_IMAGE,
            product_type: Number(productValue.product_type),
            product_price: Number(productValue.product_price),
            product_selling_price: Number(productValue.product_selling_price),
            product_discount: productValue.product_discount,
            product_variation: productValue.product_variation,
            product_category_id: productValue.product_category_id,
        };
        const updateStatus = await addToCart(productData, 1, contextValues);
        if (updateStatus) {
            contextValues.setSpinnerCubLoader(0)
        } else {
            contextValues.setSpinnerCubLoader(0)
        }
    };

    const minustocart = (productValue, index) => {
        contextValues.setSpinnerCubLoader(index)
        if (minusToCart(productValue, productValue.product_variation, contextValues)) {
            setTimeout(() => {
                contextValues.setSpinnerCubLoader(0)
            }, 500);
        } else {
            setTimeout(() => {
                contextValues.setSpinnerCubLoader(0)
            }, 500);
        }
    };
    const getUserSelectedAddress = () => {
        ApiService.fetchData("get-user-selected-address").then((res) => {
            if (res.status === "success") {
                localStorage.setItem("ADDRESS_SESSION", JSON.stringify(res.resUserAddress));
                contextValues.setUserSelectedAddress(res.resUserAddress);
                setTimeout(() => {
                    setInitialLoading(false)
                }, 500);
            } else if (res.status === "session_out") {
                contextValues.setUserToken('')
                togglePaymentModal()
            } else {
                setTimeout(() => {
                    setInitialLoading(false)
                }, 500);
            }
        });
    };
    const togglePaymentModal = () => {
        contextValues.setTogglePaymentModal(!contextValues.togglePaymentModal)
    }
    const couponModal = () => {
        contextValues.setToggleCouponModal(!contextValues.toggleCouponModal)
    }
    const removeCoupon = () => {
        localStorage.removeItem("COUPON_SESSION");
        const couponSession = localStorage.getItem("COUPON_SESSION");
        const parsedCouponSession = couponSession ? JSON.parse(couponSession) : {
            discount_amount: 0.0,
            promo_id: 0,
            promo_code: "",
        };
        contextValues.setCouponSession(parsedCouponSession)
    }

    const selectPaymentMode = (mode) => {
        setPaymentMethod(mode)
        if (mode === 'COD') {
            setSpinnerLoading(true);
            calculateShippingAmount('1')
        } else {
            setSpinnerLoading(true);
            calculateShippingAmount('0')
        }
    };

    const choutOutProccess = () => {
        if (paymentMethod === '') {
            toast.error('Please select Payment Method')
            return;
        }

        const productData = {
            sessionItemsSummary: cartSessionData
        };
        ApiService.postData("checkOrderSummary", productData).then((res) => {
            if (res.status === 'success') {
                localStorage.removeItem("CART_SESSION");
                localStorage.setItem("CART_SESSION", JSON.stringify(res.updatedSessionData));
                const cartSession = localStorage.getItem("CART_SESSION");
                const parsedCartSession = cartSession ? JSON.parse(cartSession) : [];
                contextValues.setCartSessionData(parsedCartSession)
                const hasItemWithStatusTrue = parsedCartSession.some((item) => {
                    return (item.product_item_status === true);
                });
                if (!hasItemWithStatusTrue) {
                    if (paymentMethod !== 'COD') {
                        if (contextValues.settingData.admin_payment_active === 1) {
                            createtemporder()
                        } else {
                        }
                    } else {
                        const dataString = {
                            cartSummary: cartSummary,
                            parsedAddressSession: addressSessionData,
                            parsedCartSession: parsedCartSession,
                            parsedCouponSession: couponSummary,
                            paymentMethod: paymentMethod,
                            shippingCharge: Number(shippingCharge),
                            textarea: '',
                            amount: Number(cartSummary.itemTotal) - Number(cartSummary.discount) - Number(contextValues.couponSession && contextValues.couponSession.discount_amount ? contextValues.couponSession.discount_amount : 0) + Number(shippingCharge)
                        };
                        setSpinnerLoading(true)
                        ApiService.postData("makecodorder", dataString).then((res) => {
                            if (res.status === "success") {
                                localStorage.removeItem("CART_SESSION")
                                localStorage.removeItem("ADDRESS_SESSION")
                                localStorage.removeItem("COUPON_SESSION")
                                Navigate('/thankyou/' + res.order_number)
                            } else {
                                setSpinnerLoading(false)
                            }
                        });
                    }
                } else {
                    openSummary()
                }
            } else {
                toast.error(res.message)
                return;
            }
        }).catch((error) => {
        });
    };

    const createtemporder = () => {
        const cartSession = localStorage.getItem("CART_SESSION");
        const parsedCartSession = cartSession ? JSON.parse(cartSession) : [];
        contextValues.setCartSessionData(parsedCartSession)
        const dataString = {
            cartSummary: cartSummary,
            parsedAddressSession: addressSessionData,
            parsedCartSession: parsedCartSession,
            parsedCouponSession: couponSummary,
            paymentMethod: paymentMethod,
            shippingCharge: Number(shippingCharge),
            textarea: '',
            amount: Number(cartSummary.itemTotal) - Number(cartSummary.discount) - Number(contextValues.couponSession && contextValues.couponSession.discount_amount ? contextValues.couponSession.discount_amount : 0) + Number(shippingCharge)
        }
        setSpinnerLoading(true)
        ApiService.postData("createdPaytmOrderId", dataString).then((res) => {
            if (res.status == "success") {
                setSpinnerLoading(false)
                onScriptLoad(res.row_temp_trans.temp_trans_token, res.row_temp_trans.temp_trans_order_id, res.row_temp_trans.total_amount)
            } else {
                setSpinnerLoading(false)
                toast.error(res.status)
            }

        })
    }

    const onScriptLoad = (txnToken, orderId, amount) => {
        var config = {
            "root": "",
            "flow": "DEFAULT",
            "merchant": {
                "name": "Chokhidhani Foods",
                "logo": "https://chokhidhanifoods.com/img/logo.png"
            },
            "style": {
                "headerBackgroundColor": "#8dd8ff",
                "headerColor": "#3f3f40"
            },
            "data": {
                "orderId": orderId,
                "token": txnToken,
                "tokenType": "TXN_TOKEN",
                "amount": amount
            },
            "handler": {
                "notifyMerchant": function (eventName, data) {
                    if (eventName == 'SESSION_EXPIRED') {
                        alert("Your session has expired!!");
                        window.location.reload();
                    }
                }
            }
        };
        if (window.Paytm && window.Paytm.CheckoutJS) {
            window.Paytm.CheckoutJS.init(config)
                .then(function onSuccess() {
                    console.log('Before JS Checkout invoke');
                    window.Paytm.CheckoutJS.invoke();
                })
                .catch(function onError(error) {
                    console.log('Error => ', error);
                });
        }
    };

    const openSummary = () => {
        const element = document.getElementById('orderSummaryButton');
        if (element) {
            element.click();
        }
    };

    return (
        <>
            <div className="paymentModal" backdrop="static">
                {spinnerLoading && <div className="pageLoder"><Loader type="spinner-cub" bgColor={'#fff'} color={'#fff'} size={50} /></div>}
                <div className="checkoutModalHeader">
                    <div className="checkoutModalHeaderTitle" onClick={(e) => togglePaymentModal()}>
                        <i className="d-icon-arrow-left mr-10"></i>
                        <img src="/img/logo.png" className="checkoutlogo"></img>
                    </div>
                    <img src="/img/100SECURE.png" className="wd-100"></img>
                </div>
                <div className="paymentModalContent">
                    {initialLoading ? <div className="pageLoder"><Loader type="spinner-cub" bgColor={'#fff'} color={'#fff'} size={50} /></div> : <>
                        {contextValues.cartSessionData.length > 0 && (
                            <>
                                {/* Order Summary */}
                                <div className="checkoo mb-15">
                                    <div className="checkooHeader" type="button" data-bs-toggle="collapse" data-bs-target="#orderSummary" aria-expanded="false" aria-controls="orderSummary" id="orderSummaryButton">
                                        <div className="checkooHeaderIcon">
                                            <i className="ri-shopping-bag-3-line ri-lg mr-10"></i>
                                            <span>Order Summary</span>
                                        </div>
                                        <div className="itemscount"><span className="mr-10">{contextValues.cartSessionData.length} Items</span> <i className="ri-arrow-down-s-line ri-lg ml-5"></i></div>
                                    </div>
                                    <div id="orderSummary" className="collapse">
                                        {contextValues.cartSessionData.map((value, index) => {
                                            return (
                                                <div className="checkooContent" key={value.product_id}>
                                                    <div className="ccocbox">
                                                        <div className="ccocbox-product-media">
                                                            <a href={"/product/" + value.product_slug}>
                                                                <img src={value.product_image} />
                                                            </a>
                                                        </div>
                                                        <div className="ccocbox-product-details">
                                                            <div className="ccocbox-product-title">
                                                                <a href={"/product/" + value.product_slug} className="ccocbox-product-name">{value.product_name}</a>
                                                                <a href="javascript:void(0)" className="ccocbox-trash" onClick={(e) => deleteToCart(value)}><i className="ri-delete-bin-6-line"></i></a>
                                                            </div>
                                                            <div className="ccocbox-product-footer">
                                                                <div className="qtyadd">
                                                                    {contextValues.spinnerCubLoader == index + Number(1) ? <Loader type="spinner-cub" bgColor={'#fff'} color={'#fff'} size={20} /> : <>
                                                                        {value.quantity > 1 ? <span onClick={(e) => minustocart(value, index + Number(1))}><i className="ri-subtract-line"></i></span> : <span><i className="ri-subtract-line"></i></span>}
                                                                        <span>{value.quantity}</span>
                                                                        <span onClick={(e) => plusToCart(value, index + Number(1))}><i className="ri-add-line"></i></span>
                                                                    </>
                                                                    }
                                                                </div>
                                                                <div className="ccocbox-product-price">{multiCurrency(value.product_selling_price)}</div>
                                                            </div>
                                                            {value.product_item_status ? <div style={{ 'color': 'red' }}>{value.product_item_status_message}</div> : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                {/* Price DEtails */}
                                <div className="checkoo mb-15">
                                    <div className="checkooHeader" type="button" data-bs-toggle="collapse" data-bs-target="#priceDetails" aria-expanded="false" aria-controls="priceDetails">
                                        <div className="checkooHeaderIcon">
                                            <i className="ri-money-rupee-circle-line ri-lg mr-10"></i>
                                            <span>Price Details</span>
                                        </div>
                                        <div className="itemscount">
                                            <div className="itemprice mr-10">
                                                <ins className="new-price">{multiCurrency(Number(cartSummary.itemTotal) - Number(cartSummary.discount) - Number(contextValues.couponSession && contextValues.couponSession.discount_amount ? contextValues.couponSession.discount_amount : 0) + Number(shippingCharge))}</ins>
                                            </div>
                                            <i className="ri-arrow-down-s-line ri-lg ml-5"></i></div>
                                    </div>
                                    <div id="priceDetails" className="collapse">
                                        <div className="checkooContent">
                                            <ul className="checkooPricelist">
                                                <li>
                                                    <span>Subtotal</span>
                                                    <span>{multiCurrency(cartSummary.itemTotal)}</span>
                                                </li>
                                                <li>
                                                    <span>Discount on MRP</span>
                                                    <span className="tx-green">-{multiCurrency(cartSummary.discount)}</span>
                                                </li>
                                                <li>
                                                    <span>Coupon Discount</span>
                                                    <span className="tx-green">-{multiCurrency(contextValues.couponSession.discount_amount)}</span>
                                                </li>
                                                <li>
                                                    <span>Shipping</span>
                                                    <span>{multiCurrency(shippingCharge)}</span>
                                                </li>
                                                <li className="fw700" style={{ marginTop: "10px", fontSize: '14px' }}>
                                                    <span>Total</span>
                                                    <span>{multiCurrency(Number(cartSummary.itemTotal) - Number(cartSummary.discount) - Number(contextValues.couponSession && contextValues.couponSession.discount_amount ? contextValues.couponSession.discount_amount : 0) + Number(shippingCharge))}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                {/* Coupon Section */}
                                {contextValues.couponSession.promo_id > 0 ?
                                    <div className="checkoo mb-15">
                                        <div className="checkooHeader">
                                            <div className="checkooHeaderIcon">
                                                <i className="ri-price-tag-3-line ri-lg mr-10"></i>
                                                <div style={{ lineHeight: '20px' }}>
                                                    <h6 className="tx-11 mb-0">{contextValues.couponSession.promo_code} applied successfully</h6>
                                                    <span className="tx-12">You save <span className="tx-green">{contextValues.couponSession.discount_amount}</span> on this order!</span>
                                                </div>

                                            </div>
                                            <div className="itemscount" style={{ color: 'red' }} onClick={(e) => removeCoupon()}><i className="ri-delete-bin-5-line ri-lg"></i></div>
                                        </div>
                                    </div>
                                    :
                                    <div className="checkoo mb-15" onClick={(e) => couponModal()}>
                                        <div className="checkooHeader">
                                            <div className="checkooHeaderIcon">
                                                <i className="ri-price-tag-3-line ri-lg mr-10"></i>
                                                <span>Coupons</span>
                                            </div>
                                            <div className="itemscount"><i className="ri-arrow-right-line ri-lg"></i></div>
                                        </div>
                                    </div>
                                }
                                {/* Address Section */}
                                <div className="checkoo mb-30">
                                    <div className="checkooHeader">
                                        <div className="checkooHeaderIcon">
                                            <i className="ri-map-pin-line ri-lg mr-10"></i>
                                            <span>Delivery Address</span>
                                        </div>
                                        <div className="itemscount" onClick={(e) => togglePaymentModal()}><i className="ri-edit-box-line ri-lg"></i></div>
                                    </div>
                                    <div className="addressselbox">
                                        <div className="schooseaddress">
                                            {contextValues.userSelectedAddress.ua_house_no}, {contextValues.userSelectedAddress.ua_city_name}, {contextValues.userSelectedAddress.ua_state_name}, {contextValues.userSelectedAddress.ua_pincode}
                                        </div>
                                    </div>
                                </div>
                                {/* Payment Section */}
                                <h6 className="tx-14 mb-20">Payment Methods</h6>
                                {contextValues.settingData.admin_cod_status === 1 ?
                                    <div className={`paymentbox ${paymentMethod === 'COD' ? 'active' : ''}`} onClick={(e) => selectPaymentMode('COD')}>
                                        <div className="paymentboxselect"><span className="paymentselect"></span></div>
                                        <div className="paymentboxtext ml-15">
                                            <h6 className="mb-0 tx-12">Cash on delivery</h6>
                                            <p className="mb-0 tx-11 tx-gray">Pay with cash</p>
                                        </div>
                                        <div className="paymentboxIcon" style={{ marginLeft: 'auto' }}><img src="/img/delivery.png"></img></div>
                                    </div>
                                    : null}
                                <div className={`paymentbox ${paymentMethod === 'pay-online' ? 'active' : ''}`} onClick={(e) => selectPaymentMode('pay-online')}>
                                    <div className="paymentboxselect"><span className="paymentselect"></span></div>
                                    <div className="paymentboxtext ml-10">
                                        <h6 className="mb-1 tx-12">Pay Online</h6>
                                        <p className="mb-0 tx-11">(UPI, Cards, Wallets, NetBanking)</p>
                                    </div>
                                    <div className="paymentboxIcon" style={{ marginLeft: 'auto' }}>
                                        <img src="/img/UPI.svg"></img>
                                        <img src="/img/visa.svg"></img>
                                        <img src="/img/master.svg"></img>
                                        <img src="/img/rupay.svg"></img>
                                    </div>
                                </div>
                                <div className="checkoutModalFooter">
                                    <button type="button" className="btn btn-primary btn-large btn-full" onClick={(e) => choutOutProccess()}>Pay Now</button>
                                </div>
                            </>
                        )}
                    </>
                    }
                </div>
                {contextValues.toggleCouponModal && (<CouponModal />)}
            </div>
        </>

    )
}

export default PaymentModal
