import React, { useEffect, useState, useRef, useContext } from "react";
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import Container from "react-bootstrap/Container";
import LoginModal from "../Modals/login_modal";
import CartModal from "../Modals/cart_modal";
import CouponModal from "../Modals/coupon_modal";
import Modal from "react-bootstrap/Modal";
import MenuModal from "../Modals/menu_modal";
import { ApiService } from "../../Components/Services/apiservices";
import HeaderMenu from "../Elements/header_menu";
import sessionCartData from "../../Components/Elements/cart_session_data";
import SpinnerLoader from "../Elements/spinner_loader";
import DataContext from '../Elements/eventContext';
import QuickViewModal from "../Modals/quick_view_modal";
import CheckoutModal from "../Modals/checkout_modal";
import VariationModal from "../Modals/variation_modal";
import Offers from "../Modals/offer_modal";
function Header({ cartCount = 0, state = "" }) {
  const didMountRef = useRef(true);
  const dataArray = sessionCartData();
  const [spinnerLoading, setSpinnerLoading] = useState(true);
  const [showMenuCart, setShowMenuCart] = useState(false);
  const handleShowMenuCart = () => setShowMenuCart(true);
  const handleChildMenuCartData = (status) => {
    setShowMenuCart(status);
  };
  const handleMenuClose = () => {
    setShowMenuCart(false);
  };
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [headerdata, setHeaderData] = useState({});
  const contextValues = useContext(DataContext);
  useEffect(() => {
    if (didMountRef.current) {
      contextValues.setAddressSession(dataArray[0])
      contextValues.setCartSessionData(dataArray[1])
      contextValues.setCouponSession(dataArray[2])
      contextValues.setCartSummary(dataArray[3])
      contextValues.setCartCount(dataArray[1].length)
      handleChangeCurrency();
      getheaderdata();
      getSettingsData();
      contextValues.setUserToken(localStorage.getItem("USER_TOKEN"));
    }
    didMountRef.current = false;
    if (isBrowser) {
      const handleScroll = () => {
        if (window.scrollY > 200) {
          document.getElementById("header_top").classList.add("fixed-head");
          const navbarHeight =
            document.querySelector(".fixed-header").offsetHeight;
          document.body.style.paddingTop = navbarHeight + "px";
        } else {
          document.getElementById("header_top").classList.remove("fixed-head");
          document.body.style.paddingTop = "0";
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm.length >= 2) {
        fetchSearchResults();
      }
    }, 300); // Debounce time (adjust as needed)

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const getheaderdata = () => {
    ApiService.fetchData("header-data").then((res) => {
      if (res.status === "success") {
        setHeaderData(res.headerdata);
      }
    });
  };

  const getSettingsData = () => {
    ApiService.fetchData("settings").then((res) => {
      if (res.status === "success") {
        contextValues.setSettingData(res.sitesettings);
        contextValues.setSettingImageBaseUrl(res.setting_image_path);
        contextValues.setWhatsappUrl(res.sitesettings.admin_whatsapp_no ? "https://wa.me/" + res.sitesettings.admin_whatsapp_no : "")
      }
    });
  };

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const fetchSearchResults = () => {
    const dataString = {
      query: searchTerm,
    };
    ApiService.postData("getsearchdata", dataString).then((res) => {
      if (res.status === "success") {
        setSearchResults(res.data);

        // setCatid(res.resCategory.cat_id);
      } else {
      }
    });
  };
  const wrapperRef = useRef(null);
  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setSearchResults([]);
    }
  };
  const handleBlur = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setSearchTerm("");
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const [selectedCurrency, setSelectedCurrency] = useState('INR');

  const handleChangeCurrency = () => {
    const MultiCurrencySession = localStorage.getItem("MULTI_CURRENCY");
    const parsedMultiCurrencySession = MultiCurrencySession ? JSON.parse(MultiCurrencySession) : {};
    let selectedValue = '';

    if (parsedMultiCurrencySession) {
      selectedValue = parsedMultiCurrencySession.cr_currency_select;
    } else {
      selectedValue = selectedCurrency;
    }

    const dataString = {
      selectedValue: selectedValue,
    };
    setSpinnerLoading(true);
    ApiService.postData("currency-rates", dataString).then((res) => {
      if (res.status === "success") {
        setSelectedCurrency(selectedValue);
        localStorage.setItem('MULTI_CURRENCY', JSON.stringify(res.rowCurrencyRates));
        setSpinnerLoading(false);
      } else {
        setSpinnerLoading(false);
      }
    });
  };

  const handleChange = (event) => {
    const MultiCurrencySession = localStorage.getItem("MULTI_CURRENCY");
    const parsedMultiCurrencySession = MultiCurrencySession ? JSON.parse(MultiCurrencySession) : {};
    let selectedValue = '';
    if (event) {
      selectedValue = event.target.value;
    } else {
      if (parsedMultiCurrencySession) {
        selectedValue = parsedMultiCurrencySession.cr_currency_select;
      } else {
        selectedValue = selectedCurrency;
      }
    }
    const dataString = {
      selectedValue: selectedValue,
    };
    setSpinnerLoading(true);
    ApiService.postData("currency-rates", dataString).then((res) => {
      if (res.status === "success") {
        setSelectedCurrency(selectedValue);
        localStorage.setItem('MULTI_CURRENCY', JSON.stringify(res.rowCurrencyRates));
        setSpinnerLoading(false);
        window.location.reload();
      } else {
        setSpinnerLoading(false);
      }
    });
  };

  const loginModal = () => {
    contextValues.setToggleLoginModal(!contextValues.toggleLoginModal)
  }
  const cartModal = () => {
    contextValues.setToggleCartModal(!contextValues.toggleCartModal)
  }
  const [show , setShow] = useState(false)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      {spinnerLoading && <SpinnerLoader />}
      <BrowserView>
        {headerdata.header_top ? (
          <div className="top-header">
            <Container>
              <div className="row justify-content-center align-items-center">
                <div className="col-lg-12">
                  <marquee>{headerdata.header_top}</marquee>
                </div>
              </div>
            </Container>
          </div>
        ) : null}
        <header
          className={
            state == "" ? "header fixed-header" : "header fixed-header " + state
          }
          id="header_top"
        >
          <Container>
            <div className="header-left">
              <a href="/" className="logo">
                <img src="/img/logo.png" alt="logo" width="85" height="63" />
              </a>

            </div>
            <div className="header-center">
              <HeaderMenu />
              <div className="header-search" onBlur={handleBlur} ref={wrapperRef}>
                <input type="text" name="search" autoComplete="off" placeholder="Search for products & more..." onChange={handleInputChange} value={searchTerm} />
                {searchResults && searchResults.length > 0 && searchTerm.trim() !== '' ?
                  <div className="header-search-list">
                    <ul>{
                      searchResults.map((value, index) => (
                        <a href={value.redirect}>
                          <li key={index}>{value.name}</li>
                        </a>
                      ))
                    }
                    </ul>
                  </div>
                  : ''
                }
                <button className="btn btn-search" type="button"><i className="ri-search-line"></i></button>
              </div>
            </div>
            <div className="header-right justify-content-end">

              <ul className="header-right-list">
                {contextValues.userToken ? (
                  <>
                    <li>
                      <a href="/account/account-overview">
                        <i className="ri-user-line"></i>
                        <span>Account</span>
                      </a>
                    </li>
                  </>
                ) : (
                  <>
                    <li><a href="javascript:void(0)" onClick={(e) => loginModal()}><i className="ri-user-line"></i> <span>Account</span></a></li>
                  </>
                )}
                {contextValues.userToken ? (
                  <>
                    <li>
                      <a href="javascript:void(0)">
                        <i className="ri-heart-3-line"></i>
                        <span>Wishlist</span>
                      </a>
                    </li>
                  </>
                ) : (
                  <>
                    <li><a href="javascript:void(0)" onClick={(e) => loginModal()}><i className="ri-heart-3-line"></i> <span>Wishlist</span></a></li>
                  </>
                )}
                <li>
                  <a className="cart-toggle" href="javascript:void(0)" title="cart" onClick={cartModal}>
                    <i className="ri-shopping-bag-3-line"></i>
                    <span>My Cart</span>
                    <span className="cart-count">{contextValues.cartCount}</span>
                  </a>
                </li>
              </ul>
            </div>
          </Container>
        </header>
      </BrowserView>
      <MobileView>
        {headerdata.header_top ? (
          <div className="top-header">
            <Container>
              <div className="row justify-content-center align-items-center">
                <div className="col-lg-12 ">
                  <marquee>{headerdata.header_top}</marquee>
                </div>
              </div>
            </Container>
          </div>
        ) : null}
        <header className="mheader d-flex">
          <div className="mheader-left">
            <a
              href="javascript:void(0)"
              className="sidenav-trigger"
              onClick={handleShowMenuCart}
            >
              <i className="d-icon-bars2"></i>
            </a>
            <a href="/" className="mlogo">
              <img src="/img/logo.png" alt="logo" width="65" height="47" />
            </a>
          </div>
          <div className="mheader-right">
            {/* {settingData.admin_multi_currency_status === 1 ?
              <div className="curreny-header">
                <div className="curreny-header-select">
                  <select value={selectedCurrency} onChange={handleChange}>
                    <option value="INR">INR</option>
                    <option value="USD">USD</option>
                  </select>
                </div>
              </div>
              : null} */}
            <a className="search-link" href="/search" title="search">
              <i className="ri-search-line"></i>
            </a>
            {contextValues.userToken ? (
              <>
                <a
                  className="login-link"
                  href="/account/account-overview"
                  title="login"
                >
                  <i className="ri-user-line"></i>
                </a>
              </>
            ) : (
              <>
                <a
                  className="login-link"
                  href="javascript:void(0)"
                  title="login"
                  onClick={(e) => loginModal()}
                >
                  <i className="ri-user-line"></i>
                </a>
              </>
            )}
            <a
              className="cart-toggle"
              href="javascript:void(0)"
              title="cart"
              onClick={cartModal}
            >
              <i className="ri-shopping-bag-3-line"></i>
              <span className="cart-count">{contextValues.cartCount}</span>
            </a>
          </div>
        </header>
      </MobileView>
      <LoginModal />
      {/* {contextValues.toggleCouponModal && (<CouponModal />)} */}
      {contextValues.toggleCheckoutModal && (<CheckoutModal />)}
      {contextValues.toggleCartModal && (<CartModal />)}
      {contextValues.toggleQuickViewModal && (<QuickViewModal />)}
      {contextValues.toggleVariationModal && (<VariationModal />)}
      <Modal
        show={showMenuCart}
        onHide={handleMenuClose}
        className="left menu-modal"
      >
        {showMenuCart && (
          <MenuModal
            showMenucartmodal={showMenuCart}
            onChildCartData={handleChildMenuCartData}
          />
        )}
      </Modal>
      <Offers
            
            show={show}
            setShow={setShow}
            handleClose={handleClose}
            handleShow={handleShow}
            
            />
    </>
  );
}
export default Header;
